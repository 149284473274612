import { useCallback, useMemo, useState } from 'react'
import { MyRewardsModalProps } from '../types.d'
import ModalContainer from './ModalContainer'
import { Picture } from '@components/ui'
import dayjs from 'dayjs'
import classNames from 'classnames'
import useMyRewards from '../hooks/useMyRewards'
import { ApprovalStatus, ConsumeType, RewardType } from '../const'
import clipboard from 'copy-to-clipboard'
import Tip from './Tip'
import { MULPASS_URL, BRAND } from '@shopify/const'
import { useRouter } from 'next/router'
import useCountry from '../hooks/useCountry'

function MyRewardsModal({
  shopCommon,
  common,
  data,
  ...props
}: MyRewardsModalProps) {
  const [activeTab, setActiveTab] = useState(ConsumeType.Coupon)

  const [couponPage, setCouponPage] = useState(1)
  const [productPage, setProductPage] = useState(1)
  const [copied, setCopied] = useState(-1)
  const { locale } = useRouter()
  const appLocale = useCountry({ locale })

  const tabs = useMemo(() => {
    const result = []
    if (data?.couponTab) {
      result.push({
        title: data.couponTab,
        type: ConsumeType.Coupon,
      })
    }
    if (data?.productTab) {
      result.push({
        title: data.productTab,
        type: ConsumeType.Product,
      })
    }
    return result
  }, [data])

  const myProductRewardsOptions = useMemo(() => {
    return {
      page: props.isOpen ? productPage : 0,
      pageSize: 1000,
      consumeType: ConsumeType.Product,
    }
  }, [props.isOpen, productPage])
  const myCouponRewardsOptions = useMemo(() => {
    return {
      page: props.isOpen ? couponPage : 0,
      pageSize: 1000,
      consumeType: ConsumeType.Coupon,
    }
  }, [props.isOpen, couponPage])

  const { myRewards: myCouponRewards = [], canNext: couponCanNext } =
    useMyRewards(myCouponRewardsOptions)
  const { myRewards: myProductRewards = [], canNext: productCanNext } =
    useMyRewards(myProductRewardsOptions)

  const list = useMemo(() => {
    if (activeTab === ConsumeType.Coupon) {
      return myCouponRewards.map((item) => ({
        title: item.goods_title,
        value: item.consume_credits,
        type: RewardType.Received,
        date: new Date(item.create_time * 1000),
        couponCode: item.coupon_code,
        orderNumber: '',
      }))
    }

    if (activeTab === ConsumeType.Product) {
      return myProductRewards.map((item) => {
        let type = RewardType.Pending

        if (item.fulfillment_status && item.fulfillment_status !== 'null') {
          // 物流状态
          if (item.fulfillment_status === 'fulfilled') {
            type = RewardType.Fulfilled
          } else {
            type = RewardType.Unfulfilled
          }
        } else {
          if (
            item.approval_status === ApprovalStatus.Success ||
            item.approval_status === ApprovalStatus.DoubleConfirm
          ) {
            type = RewardType.ApproveSuccess
          } else if (item.approval_status === ApprovalStatus.Failed) {
            type = RewardType.Failed
          }
        }

        return {
          title: item.goods_title,
          value: item.consume_credits,
          type,
          date: new Date(item.create_time * 1000),
          orderNumber: item.order_number,
          couponCode: '',
        }
      })
    }

    return []
  }, [activeTab, myProductRewards, myCouponRewards])

  const canNext = useMemo(() => {
    if (activeTab === ConsumeType.Coupon) {
      return couponCanNext
    }

    if (activeTab === ConsumeType.Product) {
      return productCanNext
    }

    return false
  }, [activeTab, couponCanNext, productCanNext])

  const TypeMap = useMemo(() => {
    return {
      [RewardType.Received]: data.receivedLabel,
      [RewardType.Pending]: data.pendingLabel,
      [RewardType.ApproveFail]: data.failedLabel,
      [RewardType.ApproveSuccess]: data.approveSuccessLabel,
      [RewardType.Unfulfilled]: data.unfulfilledLabel,
      [RewardType.Fulfilled]: data.fulfilledLabel,
      [RewardType.Failed]: data.failedLabel,
    }
  }, [data])

  const TipMap = useMemo(() => {
    return {
      [RewardType.Received]: '',
      [RewardType.Pending]: data.pendingTip,
      [RewardType.ApproveFail]: '',
      [RewardType.ApproveSuccess]: data.confirmedTip,
      [RewardType.Unfulfilled]: data.processingTip,
      [RewardType.Fulfilled]: data.ShippedTip,
      [RewardType.Failed]: '',
    }
  }, [data])

  const ColorMap = useMemo(() => {
    return {
      [RewardType.Received]: '#52C41A',
      [RewardType.Pending]: '#999',
      [RewardType.ApproveFail]: '#F84D4F',
      [RewardType.ApproveSuccess]: '#999',
      [RewardType.Unfulfilled]: '#999',
      [RewardType.Fulfilled]: '#999',
      [RewardType.Failed]: '#F84D4F',
    }
  }, [])

  const handleScrollEnd = useCallback(() => {
    if (!canNext) {
      return
    }

    if (activeTab === ConsumeType.Coupon) {
      setCouponPage(couponPage + 1)
    } else if (activeTab === ConsumeType.Product) {
      setProductPage(productPage + 1)
    }
  }, [activeTab, productPage, couponPage, canNext])

  const handleCopy = useCallback((code: string, idx: number) => {
    setCopied(idx)
    clipboard(code)
    setTimeout(() => {
      setCopied(-1)
    }, 2000)
  }, [])

  return (
    <ModalContainer
      title={data.title}
      className={classNames(
        'w-[640px]',
        list.length ? 'min-h-[545px]' : 'min-h-[406x]'
      )}
      useAnimation
      animationClassName="md:translate-y-[100vh]"
      {...props}
      onScrollEnd={handleScrollEnd}
    >
      <div className="flex justify-center">
        <div className="mb-[32px] flex justify-center rounded-full bg-[#f2f0f1] text-[16px] font-medium leading-[1] text-[#333]">
          {tabs.map((tab) => (
            <div
              key={tab.type}
              className={classNames(
                'cursor-pointer rounded-full px-[20px] py-[12px] font-semibold',
                activeTab === tab.type ? 'bg-[#333] text-[#fff]' : ''
              )}
              onClick={() => {
                setActiveTab(tab.type)
              }}
            >
              {tab.title}
            </div>
          ))}
        </div>
      </div>
      <div className="-mx-6 min-h-[200px] overflow-auto overscroll-contain min-l:-mx-8">
        {!list.length && (
          <div className="flex h-full flex-col items-center justify-center px-6 min-l:px-8">
            <Picture
              // @ts-ignore
              className="mb-[12px] w-[160px]"
              source={common?.emptyListImageUrl}
            ></Picture>
            <div className="text-[16px] font-medium leading-[1.4]">
              {common?.emptyListLabel}
            </div>
          </div>
        )}
        <div className="grid gap-[24px] px-6 min-l:px-8">
          {list.map((item, index) => (
            <div key={index} className="grid gap-[8px]">
              {item?.orderNumber && (
                <div className="text-[15px] font-medium leading-[1.4] text-[#777]">
                  {data.orderNumberLabel}:{' '}
                  <a
                    className="underline"
                    href={`${MULPASS_URL}/account/orders/${item.orderNumber}/?app=${BRAND}-${appLocale}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.orderNumber}
                  </a>
                </div>
              )}
              <div className="flex items-center justify-between rounded-[8px] bg-[#f7f8f9] px-[16px] py-[20px]">
                <div className="grid gap-[4px]">
                  <div className="text-[16px] font-semibold leading-[1.4]">
                    {item.title}
                  </div>
                  <div className="text-[14px] font-medium text-[#999]">
                    {data.valueLabel}: {item.value} {common?.pointUnit}
                  </div>
                  <div className="text-[14px] font-medium text-[#999]">
                    {item?.couponCode
                      ? `${shopCommon?.code || 'Code'}: ${item.couponCode}`
                      : ''}
                  </div>
                  {item?.couponCode && (
                    <div
                      className="cursor-pointer text-[14px] font-medium underline"
                      onClick={() => {
                        handleCopy(item.couponCode, index)
                      }}
                    >
                      {copied === index
                        ? shopCommon?.copied || 'COPIED'
                        : shopCommon?.copy || 'COPY'}
                    </div>
                  )}
                </div>
                <div className="grid gap-[4px] text-right">
                  <div
                    className={classNames(
                      'flex items-center justify-end text-[16px] font-semibold leading-[1.4]',
                      `text-[${ColorMap[item.type]}]`
                    )}
                  >
                    <span>{TypeMap[item.type]}</span>
                    {TipMap[item.type] && (
                      <Tip info={TipMap[item.type]} index={index} />
                    )}
                  </div>
                  <div className="text-[14px] font-medium text-[#999]">
                    {dayjs(item.date).format('YYYY-MM-DD HH:mm').toString()}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </ModalContainer>
  )
}

export default MyRewardsModal
