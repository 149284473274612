import { useEffect, useState } from 'react'
import { useProfile } from '../ProfileContext'
import { BRAND, I18N_STORE_DOMAIN } from '@shopify/const'
import { useRouter } from 'next/router'
import { RedeemLogResponse } from '../response'
import { ConsumeType } from '../const'
import { useAlpcMutation } from './useAlpcFetch'
import useCountry from './useCountry'

function useMyRewards({
  page,
  pageSize,
  consumeType,
}: {
  page: number
  pageSize: number
  consumeType?: ConsumeType
}) {
  const { locale } = useRouter()
  const { isLoading: isProfileLoading, profile } = useProfile()
  const [myRewards, setMyRewards] = useState<RedeemLogResponse[]>([])
  const [total, setTotal] = useState(0)

  const [currentPage, setCurrentPage] = useState(0)
  const country = useCountry({ locale })

  const storeDomain = I18N_STORE_DOMAIN[locale || '']
  const appName = storeDomain.replace('.myshopify.com', '') + '-shopify'

  const { isMutating: isLoading, trigger } = useAlpcMutation<
    { data: { redeem_logs: RedeemLogResponse[]; total: number } },
    {
      page: number
      page_size: number
      consume_type: ConsumeType
      user_id: string
    }
  >({
    url: `/v1/credit/log/coupon`,
    initData: {
      brand: BRAND,
      country,
      app_name: appName,
    },
  })

  useEffect(() => {
    if (isProfileLoading || !profile || isLoading) {
      return
    }

    if (page !== currentPage && page) {
      trigger(
        {
          consume_type: consumeType || ConsumeType.Coupon,
          page,
          page_size: pageSize,
          user_id: profile?.user_id,
        },
        {
          onSuccess(responseData) {
            setCurrentPage(page)
            setTotal(responseData?.data?.total)
            setMyRewards(
              myRewards.concat(responseData?.data?.redeem_logs || [])
            )
          },
        }
      )
    }
  }, [
    trigger,
    myRewards,
    consumeType,
    page,
    pageSize,
    currentPage,
    profile,
    isProfileLoading,
    isLoading,
  ])

  return {
    myRewards,
    isLoading,
    total,
    canNext: total > myRewards.length && !isLoading,
  }
}

export default useMyRewards
