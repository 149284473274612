/* eslint-disable no-unused-vars */
export enum CreditType {
  All,
  Earned = 1,
  Deducted,
}

export enum ConsumeType {
  Coupon = 1,
  Product,
  Other,
}

export enum TaskType {
  Program = 1,
  Operation,
  Redeem,
}

export enum TaskSubType {
  Activation = 1, // 激活事件
  UpdateName, // 完善姓名
  UpdatePhone, // 更新手机号
  UpdateBirthday, // 更新生日
  Subscription, // 订阅
  UpadteAppName = 7, // 完善APP姓名
  Refund = 3000, // 购物送积分任务退款
  ProductUnApproved = 5000, // 兑换商品审核不通过
}

export enum CouponType {
  FixedMount = 'fixed_amount',
  Percent = 'percent',
}

export enum RewardType {
  Received,
  Pending,
  ApproveSuccess,
  ApproveFail,
  Unfulfilled,
  Fulfilled,
  Failed,
}

export enum AlpcErrorCode {
  CodeLpcRedeemFailed = 180001,
  //user not found
  CodeLpcUserNotFound,
  //credit rule not found
  CodeLpcCreditRuleNotFound,
  //unknown error
  CodeLpcUnknownError,
  // not enough credits
  CodeLpcNotEnoughCredits,
  // user migrate
  CodeLpcUserMigrate,
  // 库存不足
  CodeLpcRuleInventoryNotEnough,
  // 兑换次数限制
  CodeLpcShopifyCouponRuleRedeemLimit = 180009,
  // 跨区兑换错误（非欧兑换欧洲的）
  CodeCrossSiteError = 180011,
}

export const ALPC_COUNTRY_MAP: Record<string, string> = {
  'eu-en': 'eu',
  'eu-de': 'de',
  'eu-it': 'it',
  'eu-fr': 'fr',
  'eu-es': 'es',
  'cz-en': 'cz',
}

export enum ApprovalStatus {
  Pending,
  Success,
  Failed,
  DoubleConfirm,
}
