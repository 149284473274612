import ReactModal from 'react-modal'
import { ModalProps } from '../types.d'
import classNames from 'classnames'
import { useEffect, useState } from 'react'

function ModalContainer({
  isOpen,
  title,
  className = '',
  overlayClassName = '',
  scrollClassName = '',
  onClose,
  onScrollEnd,
  children,
  useAnimation,
  animationClassName,
  ...props
}: ModalProps) {
  const [animationShow, setAnimationShow] = useState(false)

  useEffect(() => {
    if (!useAnimation) {
      return
    }
    requestAnimationFrame(() => {
      setAnimationShow(isOpen)
    })
  }, [isOpen, useAnimation])

  return (
    <ReactModal
      isOpen={isOpen}
      overlayClassName={classNames(
        'fixed top-0 left-0 right-0 bottom-0 z-[999] flex justify-center items-center bg-black/70 md:items-end leading-[1.2]',
        overlayClassName
      )}
      className={classNames(
        'relative flex min-h-[200px] flex-col overflow-hidden rounded-[16px] bg-white outline-none transition-all duration-300 md:h-[88.27vh] md:w-full md:rounded-b-none min-md:max-h-[calc(100vh-96px)]',
        animationShow ? '' : animationClassName,
        className
      )}
      onRequestClose={onClose}
      ariaHideApp={false}
      {...props}
    >
      <div
        className={classNames(
          'flex w-full flex-shrink-0 items-center justify-between',
          title
            ? 'h-[70px] border-b border-[#e2e2e2] px-[24px] min-l:px-[32px]'
            : 'h-[48px] px-[16px]'
        )}
      >
        <div className="text-[18px] font-semibold">{title}</div>
        <div className="h-[22px] w-[22px] cursor-pointer" onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.8525 5.31015C16.4558 4.91337 15.8125 4.91338 15.4157 5.31015L11.1268 9.5991L6.83815 5.31049C6.44138 4.91372 5.79808 4.91372 5.40131 5.31049C5.00454 5.70726 5.00455 6.35055 5.40131 6.74732L9.68993 11.0359L5.40105 15.3248C5.00428 15.7216 5.00428 16.3649 5.40105 16.7616C5.79782 17.1584 6.44111 17.1584 6.83788 16.7616L11.1268 12.4728L15.416 16.762C15.8128 17.1588 16.456 17.1588 16.8528 16.762C17.2496 16.3652 17.2496 15.7219 16.8528 15.3252L12.5636 11.0359L16.8525 6.74698C17.2493 6.35021 17.2493 5.70692 16.8525 5.31015ZM16.921 6.02856C16.921 6.22992 16.8441 6.43127 16.6905 6.5849L14.465 8.8104L12.2395 11.0359L12.2395 11.0359L16.6905 6.58493C16.8441 6.43129 16.921 6.22993 16.921 6.02856ZM5.34966 6.19087C5.37992 6.3354 5.45115 6.47307 5.56336 6.58528L10.014 11.0359L10.014 11.0359L5.56336 6.58524C5.45116 6.47304 5.37992 6.33539 5.34966 6.19087ZM5.35087 16.2121C5.38193 16.3541 5.45267 16.4891 5.56309 16.5996C5.87037 16.9068 6.36856 16.9068 6.67583 16.5996L11.1268 12.1486L15.578 16.5999C15.8853 16.9072 16.3835 16.9072 16.6908 16.5999C16.8498 16.4409 16.9265 16.2307 16.9209 16.0223C16.9265 16.2307 16.8498 16.4409 16.6908 16.5999C16.3835 16.9072 15.8853 16.9072 15.578 16.5999L11.1268 12.1487L6.67583 16.5996C6.36856 16.9069 5.87037 16.9069 5.56309 16.5996C5.45267 16.4892 5.38192 16.3541 5.35087 16.2121Z"
              fill="#333333"
            />
          </svg>
        </div>
      </div>
      <div
        className={classNames(
          'my-[32px] flex-1 overflow-auto overscroll-contain px-[24px] md:mb-[48px]',
          !title && 'mt-0',
          'min-l:px-[32px]',
          scrollClassName
        )}
        onScroll={(event) => {
          const targetElement = event.target as HTMLDivElement

          if (targetElement.scrollHeight <= targetElement.clientHeight) {
            return
          }

          if (
            targetElement.scrollTop + targetElement.clientHeight >=
            targetElement.scrollHeight - 50
          ) {
            onScrollEnd && onScrollEnd(event)
          }
        }}
      >
        {children}
      </div>
    </ReactModal>
  )
}

export default ModalContainer
