import { LOCALE_ASCRIPTION_MAP, SHORT_LOCALE_MAP } from '@shopify/const'
import { useMemo } from 'react'

type CountryProps = {
  locale?: string
}

const useCountry = ({ locale }: CountryProps) => {
  const country = useMemo(() => {
    const asLocale = LOCALE_ASCRIPTION_MAP[locale || ''] || locale
    return SHORT_LOCALE_MAP[asLocale || ''] || locale
  }, [locale])

  return country
}

export default useCountry
