import { useState } from 'react'
import { TipProps } from '../types.d'
import classNames from 'classnames'

function Tip({ info, index }: TipProps) {
  const [show, setShow] = useState(false)

  return (
    <div
      className="relative ml-[6px] cursor-pointer"
      onClick={() => {
        setShow(!show)
      }}
      onMouseOver={() => {
        setShow(true)
      }}
      onMouseLeave={() => {
        setShow(false)
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18ZM9.765 10.7118H8.316C8.316 9.71658 8.95624 9.11788 9.5487 8.56386C10.0589 8.08679 10.5336 7.64285 10.5336 7.0074C10.5336 6.2766 9.9918 5.9112 9.1854 5.9112C8.3916 5.9112 7.7994 6.327 7.5474 7.0704L6.3 6.3648C6.7788 5.13 7.938 4.5 9.2232 4.5C10.6218 4.5 11.9826 5.3316 11.9826 6.9192C11.9826 7.95842 11.3297 8.58584 10.7324 9.15979C10.2289 9.64359 9.765 10.0894 9.765 10.7118ZM9.9918 12.69C9.9918 13.2066 9.5634 13.635 9.0468 13.635C8.5176 13.635 8.1018 13.2066 8.1018 12.69C8.1018 12.1608 8.5176 11.745 9.0468 11.745C9.5634 11.745 9.9918 12.1608 9.9918 12.69Z"
          fill="#999999"
        />
      </svg>
      <div
        className={classNames(
          'absolute -right-[14px] hidden min-w-[300px] shadow-[0px_1px_12px_0px_rgba(0,0,0,0.12)]',
          index === 0 ? 'top-[33px]' : 'bottom-[33px]',
          show && '!block'
        )}
      >
        <i
          className="absolute right-[8px] blur-[12px]"
          style={
            index === 0
              ? {
                  top: '-9px',
                  borderRight: '16px solid transparent ',
                  borderLeft: '16px solid transparent ',
                  borderBottom: '8px solid rgba(0,0,0,0.12)',
                }
              : {
                  bottom: '-9px',
                  borderRight: '16px solid transparent ',
                  borderLeft: '16px solid transparent ',
                  borderTop: '8px solid rgba(0,0,0,0.12)',
                }
          }
        ></i>
        <i
          className="absolute right-[8px]"
          style={
            index === 0
              ? {
                  top: '-7px',
                  borderRight: '16px solid transparent ',
                  borderLeft: '16px solid transparent ',
                  borderBottom: '8px solid #fff ',
                }
              : {
                  bottom: '-7px',
                  borderRight: '16px solid transparent ',
                  borderLeft: '16px solid transparent ',
                  borderTop: '8px solid #fff ',
                }
          }
        ></i>
        <p className="relative block rounded-[4px] bg-white px-[14px] py-[12px]">
          <span className="block text-left text-[14px] font-medium leading-[1.4] text-[#333]">
            {info}
          </span>
        </p>
      </div>
    </div>
  )
}

export default Tip
